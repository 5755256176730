<template>
  <div v-if="rows">
    <b-card>
      <b-card-body>
        <vue-paginate-scroll
          v-if="rows"
          :src="items"
          :per-scroll="perPage"
          :observerOptions="{ threshold: 0.75 }"
        >
          <template slot-scope="{ data }">
            <app-timeline>
              <app-timeline-item
                icon="DollarSignIcon"
                v-for="(transacao, key) in data"
                v-bind:key="key"
                :variant="transacao.status_class"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                <div>
                  <h6 v-if="transacao.fluxo.parcelas">
                    {{ transacao.tipo_2 }} - {{ transacao.fluxo.parcelas }}
                  </h6>
                  <h6 v-else>
                    {{ transacao.tipo_2 }}
                  </h6>
                  <h5>R$ {{ $options.filters.formatMoney(transacao.fluxo.valor) }}</h5>
                  </div>

                  <small
                  v-if="transacao.aluno_atividade_id"
                    class="font-small-3"
                    :class="`text-${transacao.status_class}`"
                    >{{ transacao.date_at | moment("DD/MM/YYYY - H:mm") }}
                    <div>--</div>
                    Duração: {{ transacao.duracao }}</small
                  >
                </div>
                <p v-if="transacao.titulo">{{ transacao.titulo }}</p>
                <div>
                  <small class="font-small-3">Data: {{ transacao.created_at | moment("DD/MM/YYYY HH:mm") }}</small>
                </div>
                <div v-if="transacao.data_vencimento">
                  <small class="font-small-3">Vencimento: {{ transacao.data_vencimento | moment("DD/MM/YYYY") }}</small>
                </div>
                <div v-if="transacao.data_pagamento">
                  <small class="font-small-3">Data do Pagamento: {{ transacao.data_pagamento | moment("DD/MM/YYYY") }}</small>
                </div>
                <br />
                <div v-if="transacao.local">
                  <small class="font-small-3">{{ transacao.local.nome }}</small>
                  <div class="font-small-2">
                    <b-link
                      :href="
                        `https://www.google.com/maps/dir//${transacao.local.lat},${transacao.local.lng}`
                      "
                      target="_blank"
                      :class="`text-${transacao.status_class}`"
                      >{{ transacao.local.endereco }}</b-link
                    >
                  </div>
                </div>
              </app-timeline-item>
            </app-timeline>
          </template>
        </vue-paginate-scroll>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "TimeLineCalendar",
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardBody,
    BLink,
  },

  data() {
    return {
      currentPage: 1,
      perPage: 15,
      itemCollapsed: {},
    };
  },

  computed: {
    transacoes() {
      return this.$store.state.financeiro.data;
    },

    items() {
      let transacoes = this.transacoes;

      return transacoes.sort(this.sortDate);
    },

    rows() {
      return this.transacoes.length;
    },
  },

  methods: {
    sortDate(a, b) {
      let created_atA = moment(b.date);
      let created_atB = moment(a.date);

      if (created_atA.unix() < created_atB.unix()) return -1;
      if (created_atA.unix() > created_atB.unix()) return 1;
      return 0;
    },
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
};
</script>
