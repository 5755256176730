import axios from "@axios";

export default {
  namespaced: true,
  state: {
    data: [],
    saldos: {
      valor: 0,
      duracao: "0:00"
    },
    sideBarInfo: {
      isEventHandlerSidebarActive: false,
      event: {},
    },
    calendarOptions: [
      {
        color: "success",
        label: "Total recebido",
      },
      {
        color: "warning",
        label: "Total a receber",
      },
    ],
    selectedCalendars: ["Total recebido", "Total a receber"],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },

    SET_DATA(state, val) {
      state.data = val.transacoes;
      state.saldos = val.saldos;
    },
  },
  actions: {
    fetchData(ctx, { dates, filtro }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/financeiro", {
            dates: dates,
            filtro,
          })
          .then((response) => {
            this.commit("financeiro/SET_DATA", response.data);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
